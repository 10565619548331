<template>
  <div class="home">
    <navbar></navbar>
    <div class="content">
      <router-view></router-view>
      <!-- <index></index> -->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import navbar from "../components/navbar.new.vue";
import Footer from "../components/footer.vue";
import index from "../views/index.vue"
export default {
  name: "Home",
  components: {
    navbar,
    Footer,
    index
  },
  created(){
  }
};
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
.content {
  // height: calc(100% - 68px);
  // height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 60px;
}
</style>
