<template>
  <el-footer>
    <div class="content">
      <dl>
        <!-- <dt @click.stop="gotoProduct($event)">产品与服务</dt> -->
        <dt><a href="#/product">产品与服务</a></dt>
        <!-- <dd @click="gotohardware">硬件产品</dd> -->
        <dd ><a href="#/product?id=9">硬件产品</a></dd>
        <!-- <dd @click="gotosoftware">软件产品</dd> -->
          <dd ><a href="#/product?id=8">软件产品</a></dd>
        <!-- <dd @click="gotohardware_customization">硬件定制研发</dd> -->
          <dd><a href="#/product?id=7">硬件定制研发</a></dd> 
        <!-- <dd @click="gotosoftware_customization">软件定制开发</dd> -->
        <dd><a href="#/product?id=6">软件定制开发</a></dd> 
      </dl>
      <dl>
        <!-- <dt @click.stop="gotoprogramme($event)">行业解决方案</dt> -->
         <dt><a href="#/programme">行业解决方案</a></dt>
   
          <dd><a href="#/programme?name=%23content&id=0">智慧水利</a></dd>
         
        <dd><a href="#/programme?name=%23content&id=1">智慧城管</a></dd>
      
         <dd><a href="#/programme?name=%23content&id=2">智慧农业</a></dd>
               <!-- <dd @click="gotoShui">智慧水利</dd>
        <dd @click="gotoCheng">智慧城管</dd>
          <dd @click="gotoNong">智慧农业</dd> -->
      </dl>
      <dl>
        <!-- <dt @click="gotomerchants11">商务合作</dt> -->
         <dt><a href="#/merchants?name=%23opportunity">商务合作</a></dt>
        <!-- <dd @click="gotomerchants">合作范围</dd> -->
        <dd><a href="#/merchants">合作范围</a></dd>
        <!-- <dd @click="gotoConditions">合作条件</dd> -->
        <dd><a href="#/merchants?name=%23Conditions">合作条件</a></dd>
        <!-- <dd @click="gotoPolicies">合作政策</a></dd> -->
         <dd><a href="#/merchants?name=%23Policies">合作政策</a></a></dd>
        <!-- <dd @click="gotocontent_title">合作支持</dd> -->
           <dd><a href="#/merchants?name=%23content_title">合作支持</a></dd>
        <!-- <dd @click="gotoadvantage">合作优势</dd> -->
        <dd><a href="#/merchants?name=%23advantage">合作优势</a></dd>
        <!-- <dd @click="gotoProcess">合作流程</dd> -->
        <dd><a href="#/merchants?name=%23Process">合作流程</a></dd>
      </dl>
      <dl>
        <!-- <dt @click="gotoAbout">关于我们</dt> -->
        <dt><a href="#/about">关于我们</a></dt>
        <!-- <dd @click="gotoAbout">公司介绍</dd> -->
         <dd><a href="#/about">公司介绍</a></dd>
        <!-- <dd @click="gotoenterprise">企业文化</dd> -->
        <dd><a href="#/about?name=%23enterprise">企业文化</a></dd>
        <!-- <dd @click="gotoadvantages">核心优势</dd> -->
            <dd><a href="#/about?name=%23advantages">核心优势</a></dd>
        <!-- <dd @click="gotohistorys">发展历程</dd> -->
        <dd><a href="#/about?name=%23history">发展历程</a></dd>
        <!-- <dd @click="gotohonor">资质荣誉</dd> -->
        <dd><a href="#/about?name=%23honor">资质荣誉</a></dd>
      </dl>
      <dl>
        <dt>其它</dt>
        <!-- <dd @click="gotocase">成功案例</dd> -->
        <dd><a href="#/case">成功案例</a></dd>
        <!-- <dd @click="gotonews">新闻资讯</dd> -->
         <dd><a href="#/news">新闻资讯</a></dd>
        <!-- <dd @click="gotocontact">联系我们</dd> -->
        <dd><a href="#/contact">联系我们</a></dd>
        <!-- <dd @click="gotoMessage">在线留言</dd> -->
        <dd><a href="#/contact?name=%23Message">在线留言</a></dd>
      </dl>
      <div class="right_img">
        <div class="right_img img1">
          <span></span>
          <div class="right_img1">
            <img width="100px" src="../assets/img/zngzh.jpg" alt="" />
            <!-- <div><a href="/contact">在线留言</a> </div> -->
            <p>旭瑞智能公众号</p>
          </div>
        </div>
        <div class="right_img img1">
          <div class="right_img1">
            <img width="100px" src="../assets/img/zndyh.jpg" alt="" />
            <!-- <div><a href="/contact">在线留言</a> </div> -->
            <p>旭瑞智能抖音号</p>
          </div>
        </div>
        <!-- <div class="right_img img1">
          <div class="right_img1">
            <img width="100px" src="../assets/img/wxzx.png" alt="" />
            <div><a href="/contact">在线留言</a> </div>
            <p>武汉公司微信客服</p>
          </div>
        </div> -->
      </div>
    </div>
    <div style="font-size: 12px; color: #666; margin-top: 30px">
      <a href="https://beian.miit.gov.cn/" target="back">鲁ICP备18047591号 -1  鲁公网安备 37050202371316号</a
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copyright ©
      www.suree.cn, All Rights Reserved. 东营市旭瑞智能科技股份有限公司
    </div>
  </el-footer>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      imgUrl: "http://101.200.43.114:8710/jeecg-boot/sys/common/static/",
      lang: "",
      footerList: [],
      information: {},
      eqsList: [],
    };
  },
  created() {
    this.lang = localStorage.getItem("newv");
    this.getFooter();
    // console.log(this.$route);
  },
  watch: {
    $route(to, from) {
      // 监听路由是否变化
      if (to.path != from.path) {
        // this.id = to.params.id;
        this.$router.go(0);
      }

      console.log(to, from);
    },
  },
  methods: {
    ...mapMutations(["setNum"]),
    getFooter() {
      this.$API.getFooterData().then((res) => {
        // console.log("res :footer>> ", res);
        this.footerList = res.data;
      });
    },
    gotoProduct() {
      this.$router.push({ path: "/product" });
      // this.$router.go(0);
    },
    gotohardware() {
      this.$router.push({
        path: "/product",
        query: {
          id: 9,
        },
      });
      this.$router.go(0);
    },
    gotosoftware() {
      this.$router.push({
        path: "/product",
        query: {
          id: 8,
        },
      });
      this.$router.go(0);
    },
    gotohardware_customization() {
      this.$router.push({
        path: "/product",
        query: {
          id: 7,
        },
      });
      this.$router.go(0);
    },
    gotosoftware_customization() {
      this.$router.push({
        path: "/product",
        query: {
          id: 6,
        },
      });
      this.$router.go(0);
    },
    gotoprogramme() {
      this.$router.push({ path: "/programme" });
      // this.$router.go(0);
    },
    gotoShui() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 0,
        },
      });
      this.setNum(0);
      this.$router.go(0);
      // location.reload();
    },
    gotoCheng() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 1,
        },
      });
      this.setNum(8);
      this.$router.go(0);
      // location.reload();
    },
    gotoNong() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 2,
        },
      });
      this.setNum(9);
      // this.$router.go(0);
      location.reload();
    },
    gotomerchants() {
      this.$router.push({ path: "/merchants" });
      this.$router.go(0);
    },
    gotomerchants11() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#opportunity",
        },
      });
      this.$router.go(0);
    },
    gotoConditions() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#Conditions",
        },
      });
      this.$router.go(0);
    },
    gotoPolicies() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#Policies",
        },
      });
      this.$router.go(0);
    },
    gotocontent_title() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#content_title",
        },
      });
      this.$router.go(0);
    },
    gotoadvantage() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#advantage",
        },
      });
      this.$router.go(0);
    },
    gotoProcess() {
      this.$router.push({
        path: "/merchants",
        query: {
          name: "#Process",
        },
      });
      this.$router.go(0);
    },
    gotoAbout() {
      this.$router.push({ path: "/about" });
      this.$router.go(0);
    },
    gotoenterprise() {
      this.$router.push({
        path: "/about",
        query: {
          name: "#enterprise",
        },
      });
      this.$router.go(0);
    },
    gotoadvantages() {
      this.$router.push({
        path: "/about",
        query: {
          name: "#advantages",
        },
      });
      this.$router.go(0);
    },
    gotohistorys() {
      this.$router.push({
        path: "/about",
        query: {
          name: "#history",
        },
      });
      this.$router.go(0);
    },
    gotohonor() {
      this.$router.push({
        path: "/about",
        query: {
          name: "#honor",
        },
      });
      this.$router.go(0);
    },
    gotocase() {
      this.$router.push({ path: "/case" });
      this.$router.go(0);
    },
    gotonews() {
      this.$router.push({ path: "/news" });
      this.$router.go(0);
    },
    gotocontact() {
      this.$router.push({ path: "/contact" });
      this.$router.go(0);
    },
    gotoMessage() {
      this.$router.push({
        path: "/contact",
        query: {
          name: "#Message",
        },
      });
      this.$router.go(0);
    },
  },
};
</script>
<style lang="less" scoped>
.el-footer {
  height: 345px !important;
  background-color: #222429;
  color: #333;
  text-align: center;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  width: 100%;
  height: 80%;
  display: flex;
  // justify-content: center;
  margin-left: 20%;
  // margin: auto;
  a {
    color: #fff;
  }
  dl {
    margin-top: 60px;
    color: #fff;
    width: 10%;
    dt {
      font-size: 18px;
      margin-bottom: 10px;
    }
    // margin-left: -20px;
    dd {
      color: #fff;
    }
  }
  .right_img {
    display: flex;
    span {
      display: block;
      width: 1px;
      height: 200px;
      background-color: #fff;
      margin-top: 67px;
      margin-left: 50px;
    }
    .right_img1 {
      color: #fff;
      margin-top: 100px;
      margin-left: 130px;

      p {
        font-size: 12px;
      }
      div {
        width: 100px;
        height: 30px;
        background-color: #004696;
        border-radius: 15px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        margin-top: 5px;
        margin-left: 16px;
      }
    }
  }
}
dt,
dd {
  cursor: pointer;
}
dt {
  font-weight: 700;
}
</style>
