<template>
  <el-header style="box-shadow: 0 0 10px rgb(0 0 0 / 23%)">
    <div class="nav">
      <div class="logo">
        <img src="../assets/gototop/nav.png" style="width: 120px" alt="" />
      </div>
      <ul style="display: flex">
        <a href="#/" @click="check(1)">
          <li :class="{ btm: isActive == 1 }">首页</li>
        </a>
        <a href="#/product" @click="check(2)">
          <li class="product" :class="{ btm: isActive == 2 }">
            <p>产品与服务</p>
            <div class="productbox">
              <img src="../assets/img/divbj.png" alt="背景图" />
              <div class="productnav">
                <div class="box1">
                  <div class="top"><a href="#/product?id=9">硬件产品</a></div>
                  <div
                    data-v-1a96feb1=""
                    style="
                      width: 180%;
                      height: 1px;
                      border-bottom: 1px solid rgb(53, 53, 53);
                      opacity: 0.1;
                      margin-bottom: 15px;
                      margin-top: 15px;
                    "
                  ></div>
                  <div v-for="item in HardwareDetail" :key="item.index">
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
                <div class="box2">
                  <div class="top"><a href="#/product?id=0">软件产品</a></div>
                  <div
                    data-v-1a96feb1=""
                    style="
                      width: 800%;
                      height: 1px;
                      border-bottom: 1px solid rgb(53, 53, 53);
                      opacity: 0.1;
                      margin-top: 15px;
                    "
                  ></div>
                  <div class="img">
                    <img
                      src="../assets/img/矢量智能对象拷贝1.png"
                      width="21px"
                      height="17px"
                      alt="水利图标"
                    />
                    <div
                      style="
                        font-weight: bold;
                        color: #004aa1;
                        font-size: 14px;
                        margin-left: 5px;
                        margin-top: 2px;
                      "
                    >
                      <a href="#/product?id=0" style="color: #004aa1"
                        >智慧水利</a
                      >
                    </div>
                  </div>
                  <div v-for="item in softwareDetailS" :key="item.index">
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
                <div class="box3">
                  <div class="img">
                    <img
                      src="../assets/img/矢量智能对象拷贝2.png"
                      width="21px"
                      height="17px"
                      alt="城管图标"
                    />
                    <div
                      style="
                        font-weight: bold;
                        color: #004aa1;
                        font-size: 14px;
                        margin-left: 5px;
                        margin-top: 2px;
                      "
                    >
                      <a href="#/product?id=1" style="color: #004aa1"
                        >智慧城管</a
                      >
                    </div>
                  </div>
                  <div
                    v-for="item in softwareDetailC.slice(0, 10)"
                    :key="item.index"
                  >
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
                <div class="box4">
                  <div
                    v-for="item in softwareDetailC.slice(10)"
                    :key="item.index"
                  >
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
                <div class="box5">
                  <div class="img">
                    <img
                      src="../assets/img/矢量智能对象拷贝3.png"
                      width="21px"
                      height="17px"
                      alt="农业图标"
                    />
                    <div
                      style="
                        font-weight: bold;
                        color: #004aa1;
                        font-size: 14px;
                        margin-left: 5px;
                        margin-top: 2px;
                      "
                    >
                      <a href="#/product?id=2" style="color: #004aa1"
                        >智慧农业</a
                      >
                    </div>
                  </div>
                  <div v-for="item in softwareDetailN" :key="item.index">
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
                <div class="box1 box6">
                  <div class="top">
                    <a href="#/?name=%23Products&id=2">其它服务</a>
                  </div>
                  <div
                    data-v-1a96feb1=""
                    style="
                      width: 300%;
                      height: 1px;
                      border-bottom: 1px solid rgb(53, 53, 53);
                      opacity: 0.1;
                      margin-bottom: 15px;
                      margin-top: 15px;
                    "
                  ></div>
                  <div v-for="item in Other" :key="item.index">
                    <div>
                      <a :href="`${item.path}`">{{ item.name }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </a>
        <a href="#/programme" @click="check(3)">
          <li class="programme" :class="{ btm: isActive == 3 }">
            <p>行业解决方案</p>
            <div class="programmebox">
              <div style="margin-top: 15px">
                <div @click.stop="gotoShui($event)">
                  <a href="#/programme?name=%23content&id=0"
                    >智慧水利解决方案</a
                  >
                </div>
              </div>
              <div @click.stop="gotoCheng($event)">
                <a href="#/programme?name=%23content&id=1">智慧城管解决方案</a>
              </div>
              <div @click.stop="gotoNong($event)">
                <a href="#/programme?name=%23content&id=2">智慧农业解决方案</a>
              </div>
            </div>
          </li>
        </a>
        <a href="#/case" @click="check(4)">
          <li :class="{ btm: isActive == 4 }">
            <p>成功案例</p>
          </li>
        </a>
        <a href="#/merchants" @click="check(5)">
          <li :class="{ btm: isActive == 5 }">
            <p>商务合作</p>
          </li>
        </a>
        <a href="#/news" @click="check(6)">
          <li class="news" :class="{ btm: isActive == 6 }">
            <p>新闻资讯</p>
            <div class="programmebox1">
              <div style="margin-top: 15px">
                <div>
                  <a href="#/marketNews">市场趋势</a>
                </div>
              </div>
              <div>
                <a href="#/professionNews">行业动态</a>
              </div>
              <div>
                <a href="#/companyNews">企业资讯</a>
              </div>
              <div>
                <a href="#/productNews">产品信息</a>
              </div>
            </div>
          </li>
        </a>
        <a href="#/about" @click="check(7)">
          <li :class="{ btm: isActive == 7 }">
            <p>关于我们</p>
          </li>
        </a>
        <a href="#/contact" @click="check(8)">
          <li :class="{ btm: isActive == 8 }">
            <p>联系我们</p>
          </li>
        </a>
      </ul>
      <div class="search">
        <!-- <el-col >
          <el-autocomplete
            class="inline-input"
            v-model="state2"
            prefix-icon="el-icon-search"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            :trigger-on-focus="false"
            @select="handleSelect"
          ></el-autocomplete>
        </el-col> -->
        <el-input
          v-model="input"
          placeholder="请输入产品关键字"
          prefix-icon="el-icon-search"
        ></el-input>
        <!-- :style="{ opacity: num }" -->
        <div class="box" :style="{ display: num }">
          <el-tree
            ref="tree"
            :data="data"
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
            :accordion="true"
          ></el-tree>
        </div>
      </div>
      <div class="phone1">
        <img src="../assets/icon/contact.png" alt="" />
        <p>0546-8778677</p>
        <span></span>
        <p>027-59214177</p>
      </div>
    </div>
  </el-header>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      activeIndex: "1",
      input: "",
      isActive: 1,
      show: false,
      num: "none",
      data: [
        {
          label: "PLC控制柜",
          index: "HardwareDetail",
          leave: "1",
          id: "2",
        },
        {
          label: "水利控制器",
          index: "HardwareDetail",
          leave: "1",
          id: "3",
        },
        {
          label: "雨情控制器",
          index: "HardwareDetail",
          leave: "1",
          id: "4",
        },
        {
          label: "门禁报警控制器",
          index: "HardwareDetail",
          leave: "1",
          id: "6",
        },
        {
          label: "电阻采集控制器",
          index: "HardwareDetail",
          leave: "1",
          id: "5",
        },
        {
          label: "温度采集器",
          index: "HardwareDetail",
          leave: "1",
          id: "12",
        },
        {
          label: "电流电压采集器",
          index: "HardwareDetail",
          leave: "1",
          id: "13",
        },
        {
          label: "继电器控制器",
          index: "HardwareDetail",
          leave: "1",
          id: "16",
        },
        {
          label: "设备追回定位器",
          index: "HardwareDetail",
          leave: "1",
          id: "7",
        },
        {
          label: "电动车定位器",
          index: "HardwareDetail",
          leave: "1",
          id: "11",
        },
        {
          label: "柴油车定位器",
          index: "HardwareDetail",
          leave: "1",
          id: "8",
        },
        {
          label: "汽油车定位器",
          index: "HardwareDetail",
          leave: "1",
          id: "15",
        },

        {
          label: "智慧水利-大数据可视化",
          index: "softwareDetail",
          leave: "2",
          id: "1/1",
        },
        {
          label: "智慧水利-GIS一张图",
          index: "softwareDetail",
          leave: "2",
          id: "21/1",
        },
        {
          label: "无人值守远程控制系统",
          index: "softwareDetail",
          leave: "2",
          id: "22/1",
        },
        {
          label: "雨情自动遥测系统",
          index: "softwareDetail",
          leave: "2",
          id: "23/1",
        },
        {
          label: "自动水位站监测系统",
          index: "softwareDetail",
          leave: "2",
          id: "24/1",
        },
        {
          label: "自动流量站监测系统",
          index: "softwareDetail",
          leave: "2",
          id: "25/1",
        },
        {
          label: "水利设施安防预警系统",
          index: "softwareDetail",
          leave: "2",
          id: "26/1",
        },
        {
          label: "智慧水利-后台管理子系统",
          index: "softwareDetail",
          leave: "2",
          id: "27/1",
        },
        {
          label: "智慧水利-手机APP",
          index: "softwareDetail",
          leave: "2",
          id: "28/1",
        },

        {
          label: "智慧城管-大数据可视化",
          index: "#/softwareDetail",
          leave: "2",
          id: "5/2",
        },
        {
          label: "智慧城管-GIS一张图",
          index: "#/softwareDetail",
          leave: "2",
          id: "6/2",
        },
        {
          label: "智慧城管-公共服务系统",
          index: "#/softwareDetail",
          leave: "2",
          id: "7/2",
        },
        {
          label: "智慧城管-城市运行APP",
          index: "#/softwareDetail",
          leave: "2",
          id: "8/2",
        },
        {
          label: "智慧水务综合管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "9/2",
        },
        {
          label: "智慧照明管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "10/2",
        },
        {
          label: "路长制综合管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "11/2",
        },
        {
          label: "闸泵远程控制系统",
          index: "#/softwareDetail",
          leave: "2",
          id: "12/2",
        },
        {
          label: "城市防汛监管系统",
          index: "#/softwareDetail",
          leave: "2",
          id: "13/2",
        },
        {
          label: "智慧公厕综合管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "4/2",
        },
        {
          label: "智慧环卫综合管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "14/2",
        },
        {
          label: "地热井管控平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "15/2",
        },
        {
          label: "市容秩序提升平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "16/2",
        },
        {
          label: "建筑垃圾综合信息管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "17/2",
        },
        {
          label: "智慧工地管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "18/2",
        },
        {
          label: "油烟在线监测平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "19/2",
        },
        {
          label: "智慧园林综合管理平台",
          index: "#/softwareDetail",
          leave: "2",
          id: "2/2",
        },
        {
          label: "AI视频监控智能分析系统",
          index: "#/softwareDetail",
          leave: "2",
          id: "20/2",
        },

        {
          label: "智能化灌溉系统",
          index: "#/softwareDetail",
          leave: "3",
          id: "3/3",
        },
        {
          label: "水肥一体化系统",
          index: "#/softwareDetail",
          leave: "3",
          id: "29/3",
        },
        {
          label: "远程信息化虫情测报系统",
          index: "#/softwareDetail",
          leave: "3",
          id: "30/3",
        },
        {
          label: "环境监测系统（自动气象站）",
          index: "#/softwareDetail",
          leave: "3",
          id: "31/3",
        },
        {
          label: "土壤墒情监控系统",
          index: "#/softwareDetail",
          leave: "3",
          id: "32/3",
        },
        {
          label: "智能温室监控系统",
          index: "#/softwareDetail",
          leave: "3",
          id: "33/3",
        },
        {
          label: "农业遥感监测平台",
          index: "#/softwareDetail",
          leave: "3",
          id: "34/3",
        },
      ],
      state2: "",
      path: this.$router.currentRoute.fullPath,
      menuList: [
        {
          showName: "首页",
          nameEn: "Home",
          modelDec: "/",
          pathName: "index",
          index: 1,
        },
        {
          showName: "产品服务",
          nameEn: "Product service",
          modelDec: "/product",
          pathName: "product",
          index: 2,
        },
        {
          showName: "行业解决方案",
          nameEn: "Industry solutions",
          modelDec: "/programme",
          pathName: "programme",
          index: 3,
        },
        {
          showName: "成功案例",
          nameEn: "case",
          modelDec: "/case",
          pathName: "case",
          index: 4,
        },
        {
          showName: "商务合作",
          nameEn: "merchants",
          modelDec: "/merchants",
          pathName: "merchants",
          index: 5,
        },
        {
          showName: "新闻资讯",
          nameEn: "News",
          modelDec: "/news",
          pathName: "innewsdex",
          index: 6,
        },
        {
          showName: "关于我们",
          nameEn: "about",
          modelDec: "/about",
          pathName: "about",
          index: 7,
        },
        {
          showName: "联系我们",
          nameEn: "contact",
          modelDec: "/contact",
          pathName: "contact",
          index: 8,
        },
      ],
      HardwareDetail: [
        {
          name: "PLC控制柜",
          path: "#/HardwareDetail/2",
        },
        {
          name: "水利控制器",
          path: "#/HardwareDetail/3",
        },
        {
          name: "雨情控制器",
          path: "#/HardwareDetail/4",
        },
        {
          name: "门禁报警控制器",
          path: "#/HardwareDetail/6",
          leave: "2",
        },
        {
          name: "电阻采集控制器",
          path: "#/HardwareDetail/5",
          leave: "2",
        },
        {
          name: "温度采集器",
          path: "#/HardwareDetail/12",
          leave: "2",
        },
        {
          name: "电流电压采集器",
          path: "#/HardwareDetail/13",
          leave: "2",
        },
        {
          name: "继电器控制器",
          path: "#/HardwareDetail/16",
          leave: "2",
        },
        {
          name: "设备追回定位器",
          path: "#/HardwareDetail/7",
          leave: "2",
        },
        {
          name: "电动车定位器",
          path: "#/HardwareDetail/11",
          leave: "2",
        },
        {
          name: "柴油车定位器",
          path: "#/HardwareDetail/8",
          leave: "2",
        },
        {
          name: "汽油车定位器",
          path: "#/HardwareDetail/15",
          leave: "2",
        },
      ],
      softwareDetailS: [
        {
          name: "智慧水利-大数据可视化",
          path: "#/softwareDetail/1/1",
        },
        {
          name: "智慧水利-GIS一张图",
          path: "#/softwareDetail/21/1",
        },
        {
          name: "无人值守远程控制系统",
          path: "#/softwareDetail/22/1",
        },
        {
          name: "雨情自动遥测系统",
          path: "#/softwareDetail/23/1",
        },
        {
          name: "自动水位站监测系统",
          path: "#/softwareDetail/24/1",
        },
        {
          name: "自动流量站监测系统",
          path: "#/softwareDetail/25/1",
        },
        {
          name: "水利设施安防预警系统",
          path: "#/softwareDetail/26/1",
        },
        {
          name: "智慧水利-后台管理子系统",
          path: "#/softwareDetail/27/1",
        },
        {
          name: "智慧水利-手机APP",
          path: "#/softwareDetail/28/1",
        },
      ],
      softwareDetailC: [
        {
          name: "智慧城管-大数据可视化",
          path: "#/softwareDetail/5/2",
        },
        {
          name: "智慧城管-GIS一张图",
          path: "#/softwareDetail/6/2",
        },
        {
          name: "智慧城管-公共服务系统",
          path: "#/softwareDetail/7/2",
        },
        {
          name: "智慧城管-城市运行APP",
          path: "#/softwareDetail/8/2",
        },
        {
          name: "智慧水务综合管理平台",
          path: "#/softwareDetail/9/2",
        },
        {
          name: "智慧照明管理平台",
          path: "#/softwareDetail/10/2",
        },
        {
          name: "路长制综合管理平台",
          path: "#/softwareDetail/11/2",
        },
        {
          name: "闸泵远程控制系统",
          path: "#/softwareDetail/12/2",
        },
        {
          name: "城市防汛监管系统",
          path: "#/softwareDetail/13/2",
        },
        {
          name: "智慧公厕综合管理平台",
          path: "#/softwareDetail/4/2",
        },
        {
          name: "智慧环卫综合管理平台",
          path: "#/softwareDetail/14/2",
        },
        {
          name: "地热井管控平台",
          path: "#/softwareDetail/15/2",
        },
        {
          name: "市容秩序提升平台",
          path: "#/softwareDetail/16/2",
        },
        {
          name: "建筑垃圾综合信息管理平台",
          path: "#/softwareDetail/17/2",
        },
        {
          name: "智慧工地管理平台",
          path: "#/softwareDetail/18/2",
        },
        {
          name: "油烟在线监测平台",
          path: "#/softwareDetail/19/2",
        },
        {
          name: "智慧园林综合管理平台",
          path: "#/softwareDetail/2/2",
        },
        {
          name: "AI视频监控智能分析系统",
          path: "#/softwareDetail/20/2",
        },
      ],
      softwareDetailN: [
        {
          name: "智能化灌溉系统",
          path: "#/softwareDetail/3/3",
        },
        {
          name: "水肥一体化系统",
          path: "#/softwareDetail/29/3",
        },
        {
          name: "远程信息化虫情测报系统",
          path: "#/softwareDetail/30/3",
        },
        {
          name: "环境监测系统（自动气象站）",
          path: "#/softwareDetail/31/3",
        },
        {
          name: "土壤墒情监控系统",
          path: "#/softwareDetail/32/3",
        },
        {
          name: "智能温室监控系统",
          path: "#/softwareDetail/33/3",
        },
        {
          name: "农业遥感监测平台",
          path: "#/softwareDetail/34/3",
        },
      ],
      Other: [
        { name: "硬件定制研发", path: "#/product?id=12" },
        { name: "软件定制开发", path: "#/product?id=11" },
      ],
    };
  },
  watch: {
    $route: function (val) {
      this.path = val.fullPath;
    },
    input(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
    let path = this.$route.path;
    // console.log(this.$route);
    this.menuList.map((item) => {
      if (path == item.modelDec) {
        this.isActive = item.index;
      }
      if (
        this.$route.name == "HardwareDetail" ||
        this.$route.name == "SoftwareDetail"
      ) {
        this.isActive = 2;
      }
      if (
        this.$route.name == "hotarticle" ||
        this.$route.name == "NewsDetail"
      ) {
        this.isActive = 6;
      }
    });
    console.log(this.$route);
  },
  methods: {
    ...mapMutations(["setNum"]),
    gotoShui() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 0,
        },
      });
      this.setNum(0);
      location.reload();
    },
    gotoCheng() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 1,
        },
      });
      this.setNum(8);
      location.reload();
    },
    gotoNong() {
      this.$router.push({
        path: "/programme",
        query: {
          name: "#content",
          id: 2,
        },
      });
      this.setNum(9);
      location.reload();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [
        { value: "PLC控制柜", index: "HardwareDetail/2" },
        { value: "智慧水利控制器", index: "HardwareDetail/3" },
      ];
    },
    check(index) {
      this.isActive = index;
    },
    handleNodeClick(data) {
      if (data.leave == 1) {
        if (this.$route.name == "index") {
          this.$router.push({ path: `HardwareDetail/${data.id}` });
        } else if (this.$route.name == "HardwareDetail") {
          this.$router.push({ path: `${data.id}` });
        } else if (this.$route.name == "SoftwareDetail") {
          this.$router.replace(`/HardwareDetail/${data.id}`);
        }
      }
      if (data.leave == 2 || data.leave == 3) {
        console.log(this.$route);
        this.$router.replace(`/softwareDetail/${data.id}`);
      }
    },
    click(val) {
      this.$router.push(val);
    },
    // filterNode(value, data) {
    //   value = value.toUpperCase();
    //   if (!value) return true, (this.num = 0);
    //   this.num = 1;
    //   let data1 = data.label.indexOf(value);
    //   return data1 !== -1;
    // },
    filterNode(value, data) {
      value = value.toUpperCase();
      if (!value) return true, (this.num = "none");
      this.num = "block";
      let data1 = data.label.indexOf(value);
      return data1 !== -1;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped lang="less">
/deep/.el-input__inner {
  width: 110%;
}
a {
  color: #303133;
}
/deep/ .el-menu-item.is-active {
  border-bottom: 4px solid #004aa1;
}
/deep/.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
}
/deep/.el-icon-caret-right {
  display: none;
}
/deep/ .el-tree-node__label {
  margin: auto;
}
.el-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 0 10px rgb(0 0 0,0.8);
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8%;
  position: relative;
  .box {
    width: 200px;
    height: 100px;
    position: absolute;
    top: 60px;
    background-color: #fff;
  }
}
/deep/.tree {
  z-index: 1;
}
.nav {
  width: 100%;
  margin-bottom: -1px;
  display: flex;
  background-color: #fff;
  justify-content: space-evenly;
  position: relative;
  // box-shadow: 0 0 10px rgb(0 0 0 / 8%);//
  z-index: 99;
  ul {
    li {
      // width: 100px;
      padding: 0 20px;
    }
  }
  li {
    cursor: pointer;
    font-size: 16px;
    line-height: 60px;
    text-align: center;
  }
}

li:hover {
  background-color: #ccc;
}
.programme:hover .programmebox {
  height: 200px;
}
.news:hover .programmebox1 {
  height: 200px;
}
.product:hover .productbox {
  height: 600px;
}
.btm {
  border-bottom: 4px solid #004aa1;
}
.productbox {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  height: 0px;
  overflow: hidden;
  background-color: #fff;
  transition: 0.3s;
  opacity: 0.9;
  z-index: 99;
}
.productnav {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 5%;
  display: flex;
  .box1 {
    margin-top: 20px;
    .top {
      a {
        font-weight: bold;
        color: rgb(0, 74, 161);
        font-size: 18px;
      }
    }
    div {
      line-height: 40px;
      text-align: left;
      a {
        font-size: 14px;
      }
    }
    div:hover a {
      color: #004aa1;
    }
  }
  .box2 {
    margin-top: 20px;
    margin-left: 150px;
    .top {
      a {
        font-weight: bold;
        color: rgb(0, 74, 161);
        font-size: 18px;
      }
    }
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -75px;
      margin-top: 15px;
    }
    div {
      line-height: 40px;
      text-align: left;
      a {
        font-size: 14px;
      }
    }
    div:hover a {
      color: #004aa1;
    }
  }
  .box3 {
    margin-top: 80px;
    margin-left: 120px;
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -60px;
      margin-top: 12px;
    }
    div {
      line-height: 40px;
      text-align: left;
      a {
        font-size: 14px;
      }
    }
    div:hover a {
      color: #004aa1;
    }
  }
  .box4 {
    margin-top: 130px;
    margin-left: 180px;
    div {
      line-height: 40px;
      text-align: left;
      a {
        font-size: 14px;
      }
    }
    div:hover a {
      color: #004aa1;
    }
  }
  .box5 {
    margin-top: 80px;
    margin-left: 180px;
    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -100px;
      margin-top: 12px;
    }
    div {
      line-height: 40px;
      text-align: left;
      a {
        font-size: 14px;
      }
    }
    div:hover a {
      color: #004aa1;
    }
  }
  .box6 {
    margin-left: 200px;
  }
}
.programme,
.news {
  position: relative;
}
.programmebox {
  position: absolute;
  top: 64px;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 0.3s;
  opacity: 0.9;
  font-size: 14px;
  a:hover {
    color: #004aa1;
  }
}
.programmebox1 {
  position: absolute;
  top: 64px;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 0.3s;
  opacity: 0.9;
  font-size: 14px;
  line-height: 40px;
  a:hover {
    color: #004aa1;
  }
}

.phone1 {
  display: flex;
  line-height: 60px;
  // margin-top: 6px;
  img {
    width: 20px;
    height: 20px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 30px;
  }
  p {
    font-weight: 500;
    margin-left: 0px;
  }
  span {
    display: block;
    width: 1px;
    height: 16px;
    background-color: #000;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 22px;
  }
}
</style>